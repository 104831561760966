<template>
  <div class="panel inline">
    <ConfirmModal v-if="recipeForDeletion" item-type="Plan Recipe" :item-name="recipeForDeletion.name" :confirm="confirmDeletePlanRecipe" :cancel="cancelDeletePlanRecipe" />
    <div class="panel-body sides">
      <div class="side first">
        <div class="panel-header">
          <p>Add Recipe to Plan</p>
          <SearchBox v-model="recipeSearch" name="Ingredients" />
        </div>
        <div class="in-panel">
          <div class="full-scroller in-panel">
            <ListAddForm :items="allRecipes" :form-component="AddPlanRecipeForm" :submit="addNewRecipe" />
          </div>
        </div>
      </div>
      <div class="main">
        <ListPanel title="Plan Ingredients" :fields="fields" :items="allPlanRecipes" :on-row="goto" :on-delete="askToDeletePlanRecipe" export-file="plan_recipes.csv" />
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ConfirmModal from "@/components/ui/ConfirmModal";
import ListPanel from "@/components/ui/ListPanel";
import {actionIf, delayedIfLoading} from "@/lib/Async";
import {mealNutrition, recipeVariations} from "@/lib/NutritionUtils";
import AddPlanRecipeForm from "@/components/forms/AddPlanRecipeForm";
import SearchBox from "@/components/ui/SearchBox";
import ListAddForm from "@/components/ui/ListAddForm";

export default {
  name: "Recipes",
  components: {ListPanel, ConfirmModal, SearchBox, ListAddForm  },
  data() {
    return {
      AddPlanRecipeForm,
      recipeForDeletion: null,
      recipeSearch: "",
      search : "",
      fields : [
        {label: "#", prop: "id", sortable: true, width: "60px" },
        {label: "Name", prop: "name", primary: true, sortable: true },
          {label: "Instructions", prop: "instructions", sortable: true, format : (row) => row.length + ' rows' },
        {label: "Actions", prop: "", width: "10%", actions: [
            {label: "x", type: "danger-btn", action: "delete", tooltip: "Delete"}
          ]}
      ]
    }
  },

  async mounted() {
    await delayedIfLoading(!this.isLoggedIn, actionIf([
          [!this.allPlanRecipes.length, this.getPlanRecipes],
          [!this.allRecipes.length, this.getRecipes],
        ]), this.setLoading,
        () => this.isLoggedIn, () => this.$router.push('/'))
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allRecipes', 'allPlanRecipes']),
  },

  methods: {
    ...mapActions(['setLoading','getRecipes','getPlanRecipes','deletePlanRecipe','createPlanRecipe']),
    goto(recipe) {
      this.$router.push('/plan-recipe/'+recipe.id)
    },
    async addNewRecipe(id, name) {

      if (!id || !name) return false;
      await this.setLoading(true);

      try {
        await this.createPlanRecipe({recipe_id: id,  name: name.trim()})
      }
      catch(e) {
        console.log("Error!")
      }

      await this.setLoading(false);
      this.addIngredient = null;

    },

    rowNutrition(recipe_id, prop) {
      let variations;
      if (this.allPlanRecipes) {
        const target = this.allPlanRecipes.find(it => it.id === recipe_id);
        console.log(target)
        if (target) {
          const nutrition = mealNutrition(this.allMealIngredients(target), false, this.allIngredients, true);
          variations = recipeVariations(this.allMealIngredients(target))
          const nutritionMax = mealNutrition(variations[variations.length-1], false, this.allIngredients, true, true);

          if (nutritionMax[prop] > nutrition[prop]) return nutrition[prop]+'-'+nutritionMax[prop]
          return nutrition[prop];
        }
      }
        return 0
    },
    rowPercentage(recipe_id, prop, factor) {
      let res;
      if (this.allPlanRecipes) {
        const target = this.allPlanRecipes.find(it => it.id === recipe_id);
        if (target) {
          const nutrition = mealNutrition(this.allMealIngredients(this.allPlanRecipes.find(it => it.id === recipe_id)), false, this.allIngredients, true);
          res = (nutrition[prop] * factor / nutrition.calories * 100).toFixed(2);
        }

        return res;
      }
      return 0
    },

    allMealIngredients(meal) {
      const recipes = meal && meal.recipes ? [...meal.recipes] : []
      if (meal.recipes) {
        for (let recipe of meal.recipes) {
          if (recipe.recipe_id) {
            const rec = this.allRecipes.find(it => it.id === recipe.recipe_id);
            if (rec) {
              for (let recipe of rec.recipes) recipes.push(recipe)
            }
          }
        }
      }
      return recipes
    },

    askToDeletePlanRecipe(recipe) {
      console.log({recipe})
      this.recipeForDeletion = recipe;
    },
    cancelDeletePlanRecipe() { this.recipeForDeletion = null },
    confirmDeletePlanRecipe() {
      this.deletePlanRecipe(this.recipeForDeletion.id)
      this.recipeForDeletion = null
    }
  },

}
</script>
