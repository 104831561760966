export default [
    { label: "Name", prop: "name", type: "text", group: "primary", inputClass: "diagonal-wrap" },
    { label: "Type", prop: "type", type: "text", group: "primary 2",},
    { label: "Subtitle", prop: "subtitle", type: "text", group: "primary 3"},
    { label: "Short Description", prop: "short_description", type: "text", group: "primary 3"},
    { label: "Description", prop: "description", type: "textarea", group: "primary 4" },
    { label: "Rules (1 per line)", prop: "rules", type: "textarea", group: "primary 4" },
    { label: "Limit Tags (comma seperated)", prop: "limit_tags", type: "text", group: "primary 5" },
    { label: "Limit Categories (comma seperated)", prop: "limit_categories", type: "text", group: "primary 5" },

]
