<template>
  <ul class="tag-list">
    <li v-for="(tag, ii) in tags" :key="tag.id" class="tag">
      <span class="content">
        <span>{{tagValue(tag)}}</span>
        <b v-if="secondary" class="tag-secondary">{{tag[secondary]}}{{secondarySuffix||''}}</b>
      </span>
      <span v-if="!passive" class="close-btn" @click="() => removeTag(ii)"></span>
    </li>
  </ul>
</template>

<script>
export default {
  name: "TagList",
  props : ['allTags', 'tagKey', 'tags', 'prop','secondary','secondarySuffix','passive'],
  methods : {
    removeTag(id) {
      this.$emit('remove-tag', id)
    },
    tagValue(t) {
      return t[this.prop]
    },
  }
}
</script>
