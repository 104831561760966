<template>
    <div class="panel inline">
      <ConfirmModal v-if="categoryForDeletion" item-type="Category" :item-name="categoryForDeletion.name" :confirm="confirmDeleteCategory" :cancel="cancelDeleteCategory" />
      <ListPanel title="Categories" :fields="fields" :items="allCategories" :on-add="addNewCategory" :on-delete="askToDeleteCategory" :on-row="goto" />
    </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ConfirmModal from "@/components/ui/ConfirmModal";
import ListPanel from "@/components/ui/ListPanel";
import {actionIf, delayedIfLoading} from "@/lib/Async";

export default {
  name: "Categories",
  components: {ListPanel, ConfirmModal },

  data() {
    return {
      categoryForDeletion: null,
      search : "",
      fields : [
        {label: "Name", prop: "name", primary: true, sortable: true},
        {label: "Actions", prop: "", width: "10%", actions: [
            {label: "x", type: "danger-btn", action: "delete", tooltip: "Delete"}
          ]}
      ]
    }
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allCategories', 'allCategoryRoles', 'allLocales', 'allPlans']),
  },

  async mounted() {
    await delayedIfLoading(!this.isLoggedIn, actionIf([
        [!this.allCategories.length, this.getCategories],
        ]), this.setLoading,
        () => this.isLoggedIn, () => this.$router.push('/'))
  },

  methods: {
    ...mapActions(['getCategories','setLoading','deleteCategory', 'getLocales', 'getPlans']),
    goto(category) {
      this.$router.push('/plan-category/'+category.id)
    },
    addNewCategory() {
      this.$router.push('/plan-category/new')
    },
    askToDeleteCategory(category) {
      this.categoryForDeletion = category;
    },
    cancelDeleteCategory() { this.categoryForDeletion = null },
    confirmDeleteCategory() {
      this.deleteCategory(this.categoryForDeletion.id)
      this.categoryForDeletion = null
    }
  },
}
</script>
