<template>
  <div class="settings-panel">
    <div class="panel-header">
      <p>General Settings</p>
    </div>
    <div class="panel-body">
      <div class="sides">
        <div class="main">
          <InfoBox v-if="myPlan" :title="myPlan.name+' plan settings'" class="form-field">
            <p>You can set your plan settings here.</p>
            <span class="bold">Be careful!</span> these settings can be extremely fragile, make sure you know what you are doing or you might risk breaking your menus.
          </InfoBox>
          <div class="form-field">
            <label>Editor Title</label>
            <div class="form-input">
              <input v-model="editorTitle" placeholder="Fito Nutrition" type="text" />
            </div>
          </div>

          <InfoBox v-if="allSnapshots">
            <h3>Foods API Snapshot</h3>
            <div class="form-field">
              <label>Snapshot</label>
              <div class="form-input">
                <v-select v-model="foods_snapshot" :options="snaps" :reduce="it => it.name" label="name" :clearable="false"></v-select>
              </div>
              <div class="form-input">
                <button @click="loadSnapshot">Load Snapshot</button>
              </div>
            </div>
            <div class="even fill" v-if="snap">
              <div>Date: {{new Date(snap.date).toLocaleDateString()}}</div>
              <div>Size: {{(snap.size / 1024 / 1024).toFixed(2)}}mb</div>
              <div>{{snap.description}}</div>
            </div>
          </InfoBox>


        </div>
        <div class="side">
          <div class="box">
            <div class="diagonal-wrap">
              <button class="large-button" @click="onSubmit">Save Settings</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import InfoBox from "@/components/ui/InfoBox";
import {actionIf} from "@/lib/Async";

export default {
  name: "GeneralSettings",
  components: {InfoBox},

  data() {
    return {
      editorTitle: '',
      foods_snapshot: ''
    }
  },

  async mounted() {
    await actionIf([
      [!this.myPlan, this.getMyPlan],
    ])

    await this.getAvailableSnapshots();

    this.editorTitle = this.myPlan.editor_title;
    this.foods_snapshot = this.myPlan.foods_snapshot;
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'isLoading', 'success', 'appError', 'currentUser','currentPlan','myPlan', 'allSnapshots']),
    snaps() {
      return [ ...this.allSnapshots]
    },
    snap() {
      return this.allSnapshots.find(it => it.name === this.foods_snapshot)
    }
  },

  methods : {
    ...mapActions(['logout', 'setLoading', 'setCurrentPlan', 'setSnapshot', 'setEditorTitle','setSuccess','getMyPlan', 'getAvailableSnapshots',
      'getRecipes',
    ]),
    async onSubmit() {
      await this.setEditorTitle([this.currentPlan, this.editorTitle])
      await this.setSuccess("Plan Settings saved");
    },

    async loadSnapshot() {
      if (!this.foods_snapshot) return;
      await this.setLoading(true)
      await this.setSnapshot([this.myPlan.id, this.foods_snapshot])
      await this.setLoading(false)
      await this.setSuccess("Loaded Snapshot!");

      await Promise.all([this.getRecipes()])

    }
  },
}
</script>
