<template>
  <div class="panel inline">
    <div class="panel-body sides">
      <div class="side first">
        <div class="panel-header">
          <p>Plan Settings</p>
        </div>
        <div class="panel-body">
          <div class="full-scroller in-panel">
            <SideMenu :items="menuItems" :path="path" @change="onMenuItem" />
          </div>
        </div>
      </div>
      <div class="main">
        <div class="full-scroller">
          <GeneralSettings v-if="path==='/plan-settings'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideMenu from "@/components/ui/SideMenu";
import GeneralSettings from "@/components/views/Plan/PlanSettings/GeneralSettings";

const menuItems = [
  { path: '/plan-settings', label: "General Settings" },
]

export default {
  name: "PlanSettings",

  components: {SideMenu, GeneralSettings},

  data: function () {
    return {
      menuItems,
      path: ''
    }
  },

  async mounted() {

    this.path = this.$router.currentRoute.path;
  },

  async updated() {

  },

  methods : {
    onMenuItem(item) {
      this.path = item.path;
      if (item.path !== this.$router.currentRoute.path) this.$router.push(item.path)
    },
  },

  watch : {
    $route() {
      this.path = this.$router.currentRoute.path;
    }
  },



}

</script>
