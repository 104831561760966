import Vuex from 'vuex';
import Vue from 'vue';
import locale from './modules/locale';
import login from './modules/login';
import ingredients from './modules/ingredients';
import menusPlans from './modules/menus-plans';
import recipes from './modules/recipes';
import categories from './modules/categories';
import tags from './modules/tags';
import plans from './modules/plans';
import userRoles from './modules/user-roles';
import servingSizes from './modules/serving-sizes';
import users from './modules/users';
import userPermissions from './modules/user-permissions';

Vue.use(Vuex);

export default new Vuex.Store({
    modules : {
        categories,
        ingredients,
        servingSizes,
        locale,
        login,
        menusPlans,
        recipes,
        plans,
        tags,
        users,
        userRoles,
        userPermissions,
    }
})
