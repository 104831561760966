import Home from "@/components/views/Home";
import Plans from "@/components/views/Editor/Plans";
import Plan from "@/components/views/Editor/Plan";
import Roles from "@/components/views/Admin/Roles";
import Role from "@/components/views/Admin/Role";
import Users from "@/components/views/Admin/Users";
import User from "@/components/views/Admin/User";
import Recipe from "@/components/views/Plan/Recipe";
import Categories from "@/components/views/Plan/Categories";
import Recipes from "@/components/views/Plan/Recipes";
import PlanIcons from "@/components/views/Plan/PlanIcons";
import PlanIcon from "@/components/views/Plan/PlanIcon";
import PlanSettings from "@/components/views/Plan/PlanSettings";
import Category from "@/components/views/Plan/Category";
import Tag from "@/components/views/Plan/Tag";
import Tags from "@/components/views/Plan/Tags";
import MenusPlans from "@/components/views/Plan/MenusPlans";
import MenusPlan from "@/components/views/Plan/MenusPlan";

export default [
    // admin routes
    { path: '/roles', component: Roles, keepAlive: true },
    { path: '/role/:id', component: Role, keepAlive: true },
    { path: '/users', component: Users, keepAlive: true },
    { path: '/user/:id', component: User, keepAlive: true },

    // editor routes

    { path: '/', component: Home, keepAlive: true },
    { path: '/plans', component: Plans, keepAlive: true },
    { path: '/plan/:id', component: Plan, keepAlive: true },

    // plan routes

    { path: '/plan-recipes', component: Recipes, keepAlive: false},
    { path: '/plan-recipe/:id', component: Recipe, keepAlive: false},


    { path: '/plan-categories', component: Categories, keepAlive: false},
    { path: '/plan-category/:id', component: Category, keepAlive: false},

    { path: '/plan-menus-plans', component: MenusPlans, keepAlive: false},
    { path: '/plan-menus-plans/:id', component: MenusPlan, keepAlive: false},

    { path: '/plan-tags', component: Tags, keepAlive: false},
    { path: '/plan-tag/:id', component: Tag, keepAlive: false},

    { path: '/plan-icons', component: PlanIcons, keepAlive: false},
    { path: '/plan-icon/:id', component: PlanIcon, keepAlive: false},

    { path: '/plan-settings', component: PlanSettings, keepAlive: false},
    { path: '/plan-settings/:section', component: PlanSettings, keepAlive: false},
];
