<template>
  <div class="checkboxes-form">
    <label v-for="(item, index) in items" :key="index">
      <input type="checkbox" :checked="checked[index]" @change="() => onCheck(index)" :ref="'cb_'+index" /><span>{{item[labelProp]}}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: "CheckboxesForm",
  props: ["data", "items", "labelProp", "valueProp", "dataProp", "exportValueProp", "onChange"],
  data() {
    return {
      cb : [],
      checked : [],
    }
  },
  mounted() {
    setTimeout(() => this.resetItems(), 2000)
    this.resetItems();
  },

  methods : {
    resetItems() {
      if (!this.data || !this.data.length) return;
      for (let i = 0; i < this.items.length; i++) {
      console.log(this.data, this.items[i], this.valueProp)
        if (this.items[i].checked || this.data.find(it => it[this.dataProp?this.dataProp:this.valueProp] === this.items[i][this.valueProp])) {
          this.$set(this.checked, i, true)
        }
        else {
          this.$set(this.checked, i, false)
        }
      }
    },
    onCheck(index) {
      this.checked[index] = !this.checked[index]

      let res = [], r;
      for (let i = 0; i < this.items.length; i++) {
        r = {...this.items[i]}
        if (this.exportValueProp) r = {...r, [this.exportValueProp] : r[this.valueProp]}
        if (this.checked[i]) res.push(r)
      }
      this.onChange(res)
    }
  },

  watch : {
    items() {
      setTimeout(() => this.resetItems(), 1000)
    }
  }
}
</script>
