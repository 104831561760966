<template>
  <ul :class="cls">
    <li v-for="(item, index) in items" :key="index" :class="matched(item)?'active':''" @click="onClick(item)">
      <span>{{item[prop]}}</span>
    </li>
  </ul>
</template>

<script>
export default {
  name: "BooleanList",
  props:['items','selected', 'prop', 'inline','match'],
  computed: {
    cls() {return 'boolean-list' + (this.inline&&' inline')}
  },

  data() {
    return {
      sel : []
    }
  },

  mounted() {
    this.sel = this.selected ? (typeof this.selected === "string" ? this.selected.split(",").map(it=>it.trim()*1) : this.selected) : []
  },

  methods : {
    matched(item) {
      if (this.sel) return this.sel.indexOf(item[this.match]) > -1
      return this.sel?this.sel.indexOf(item) > -1:false
    },
    onClick(item) {
      if (this.sel) this.$emit('change', item, !(this.sel.indexOf(item[this.prop]) > -1));

      if (this.sel.indexOf(item[this.match]) > -1) {
        this.sel.splice(this.sel.indexOf(item[this.match]), 1)
      }
      else {
        this.sel.push(item[this.match])
      }

      this.$emit('status', this.sel)
    }
  },

  watch : {
    selected() {
      this.sel = this.selected
    }
  }
}
</script>
