<template>
    <div class="panel inline">
      <ConfirmModal v-if="tagForDeletion" item-type="Tag" :item-name="tagForDeletion.name" :confirm="confirmDeleteTag" :cancel="cancelDeleteTag" />
      <ListPanel title="Tags" :fields="fields" :items="allTags" :on-add="addNewTag" :on-delete="askToDeleteTag" :on-row="goto" />
    </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ConfirmModal from "@/components/ui/ConfirmModal";
import ListPanel from "@/components/ui/ListPanel";
import {actionIf, delayedIfLoading} from "@/lib/Async";

export default {
  name: "Tags",
  components: {ListPanel, ConfirmModal },

  data() {
    return {
      tagForDeletion: null,
      search : "",
      fields : [
        {label: "Name", prop: "name", primary: true, sortable: true},
        {label: "Actions", prop: "", width: "10%", actions: [
            {label: "x", type: "danger-btn", action: "delete", tooltip: "Delete"}
          ]}
      ]
    }
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allTags', 'allTagRoles', 'allLocales', 'allPlans']),
  },

  async mounted() {
    await delayedIfLoading(!this.isLoggedIn, actionIf([
        [!this.allTags.length, this.getTags],
        ]), this.setLoading,
        () => this.isLoggedIn, () => this.$router.push('/'))
  },

  methods: {
    ...mapActions(['getTags','setLoading','deleteTag', 'getLocales', 'getPlans']),
    goto(tag) {
      this.$router.push('/plan-tag/'+tag.id)
    },
    addNewTag() {
      this.$router.push('/plan-tag/new')
    },
    askToDeleteTag(tag) {
      this.tagForDeletion = tag;
    },
    cancelDeleteTag() { this.tagForDeletion = null },
    confirmDeleteTag() {
      this.deleteTag(this.tagForDeletion.id)
      this.tagForDeletion = null
    }
  },
}
</script>
