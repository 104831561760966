<template>
  <div :class="'nutrition-counter'+(compact?' compact':'')">
    <h3>{{ label ? label : 'Nutrients' }}</h3>
    <ul class="nutrients">
      <li v-for="(nut,name) in nutrients" :key="name">
        <div class="label">{{name}}</div>
        <div class="value">{{combined(name)}}</div>
      </li>
    </ul>
    <div v-if="!compact">
      <h3>Summary</h3>
      <ul class="nutrients">
        <li>
          <div class="label">Protein</div>
          <div class="value">{{(nutrients.protein_g*4 / nutrients.calories * 100).toFixed(2)}}%</div>
        </li>
        <li>
          <div class="label">Fat</div>
          <div class="value">{{(nutrients.fat_g*9 / nutrients.calories * 100).toFixed(2)}}%</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {mealNutrition, recipeVariations} from "@/lib/NutritionUtils";

export default {
  name: "NutritionCounter",
  props: ['ingredients','compact','label','name'],

  data() {
    return {
      'fields': ['calories','protein_g','fat_g','carbs_g','sugars_g','fiber_g','saturated_fat_g','trans_fat_g','cholesterol_mg'],
      variations : [],
      showVariations : false
    }
  },

  mounted() {
    if (this.ingredients && this.allIngredients) this.resetVariations()
  },

  computed : {
    ...mapGetters([]),
    nutrients() {
      const n = {};

      for (let ing of this.ingredients) {
        if (!ing.ingredient || !ing.ingredient.id) {
          if (ing.ingredient_id && this.allIngredients) ing.ingredient = this.allIngredients.find(it => it.id === ing.ingredient_id)
          if (!ing.ingredient) continue;
        }

        for (let nut in ing.ingredient.nutrition) {
          if (this.fields.indexOf(nut) < 0) continue;
          if (isNaN(ing.ingredient.nutrition[nut]*1)) continue;
          if (!n[nut]) n[nut] = 0;
          n[nut] += ing.ingredient.nutrition[nut] / 100 * ing.amount_g * ing.serving_amount;
        }
      }
      return n;
    }
  },
  methods : {
    combined(prop) {
      const nuts = this.nutrients
      let res = nuts[prop].toFixed(2)
      if (this.maxNutrition) res += " - " + this.maxNutrition[prop].toFixed(2)
      return res
    },

    variationAmount(ing) {
      const fullAmount = ing.serving_amount * ing.amount_g,
          servingAmount = ing.max && ing.serving_size_id ? Math.min(ing.serving_amount, ing.max) : ing.serving_amount,
          amount = ing.max ? Math.min(fullAmount, ing.serving_size_id ? ing.max * ing.amount_g : ing.max) : fullAmount

      return ing.serving_size_id ? 'x'+servingAmount : amount.toFixed(2)*1 + (ing.unit_type || 'g')

    },

    variationGAmount(ing) {
      const fullAmount = ing.serving_amount * ing.amount_g,
          amount = ing.max ? Math.min(fullAmount, ing.serving_size_id ? ing.max * ing.amount_g : ing.max) : fullAmount
      return amount.toFixed(2)*1 + (ing.unit_type || 'g') +(fullAmount>amount?' (max)':'')
    },

    variationsNutrition(recipe) {
      return recipeVariations(recipe).map(it => mealNutrition(it, true, this.allIngredients, true))
    },

    resetVariations() {
      this.variations = recipeVariations(this.ingredients)
      this.maxNutrition = this.variations.length > 1 ? mealNutrition(this.variations[this.variations.length-1], true, this.allIngredients, true) : null
    },
  },

  watch : {
    ingredients() { this.resetVariations() },
    allIngredients() { this.resetVariations() }
  }
}
</script>
