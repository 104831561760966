<template>
    <div class="panel inline">
      <ConfirmModal v-if="planIconForDeletion" item-type="Icon" :item-name="null" :confirm="confirmDeleteIcon" :cancel="cancelDeleteIcon" />
      <ListPanel :fields="fields" title="Icons" :items="allPlanIcons" :on-row="goto" :on-delete="askToDeleteIcon" :on-add="addNewIcon" />
    </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ConfirmModal from "@/components/ui/ConfirmModal";
import ListPanel from "@/components/ui/ListPanel";
import {actionIf, delayedIfLoading} from "@/lib/Async";

export default {
  name: "Icons",
  components: {ListPanel, ConfirmModal },
  data() {
    return {
      planIconForDeletion: null,
      search : "",
      fields : [
        {label: "Color", prop: "color", width: "20%", primary: true, color: true},
        {label: "Image", prop: "image", image: true, imageBackground: 'color'},
        {label: "Actions", prop: "", width: "10%", actions: [
            {label: "x", type: "danger-btn", action: "delete", tooltip: "Delete"}
          ]}
      ]
    }
  },

  async mounted() {
    await delayedIfLoading(!this.isLoggedIn, actionIf([!this.allPlanIcons.length, this.getPlanIcons]),
        this.setLoading, () => this.isLoggedIn, () => this.$router.push('/'))
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allPlanIcons']),
    filteredIcons() {
      return this.search.length >= 1 ?
          this.allPlanIcons.filter(it => it.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1) :
          this.allPlanIcons
    }
  },

  methods: {
    ...mapActions(['getPlanIcons','setLoading','deletePlanIcon']),
    goto(planIcon) {
      this.$router.push('/plan-ingredient-icon/'+planIcon.id)
    },
    addNewIcon() {
      this.$router.push('/plan-ingredient-icon/new')
    },
    askToDeleteIcon(planIcon) {
      this.planIconForDeletion = planIcon;
    },
    cancelDeleteIcon() { this.planIconForDeletion = null },
    confirmDeleteIcon() {
      this.deletePlanIcon(this.planIconForDeletion.id)
      this.planIconForDeletion = null
    }
  },

}
</script>
