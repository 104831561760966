import Api, {ApiCall, ApiRequest} from "@/lib/Api";

export default {
    state : {
        recipes : [],
        planRecipes: [],
    },
    getters : {
        allRecipes: state => state.recipes,
        allPlanRecipes: state => state.planRecipes
    },
    actions : {
        async getRecipes({commit}) {
            const {recipes} = await ApiRequest(ApiCall(Api.ListRecipes));
            return await commit('setRecipes', recipes);
        },

        async getPlanRecipes({commit}) {
            const {recipes} = await ApiRequest(ApiCall(Api.ListPlanRecipes));
            await commit('setPlanRecipes', recipes);
            return true
        },
        async createPlanRecipe({commit}, recipe) {
            if (recipe.recipe_type instanceof Array) recipe.recipe_type = recipe.recipe_type.join(",")
            const res = await ApiRequest(ApiCall(Api.CreatePlanRecipe, recipe))
            recipe.id = res.id
            return await commit('createPlanRecipe', recipe);
        },
        async updatePlanRecipe({commit}, [recipe, id]) {
            if (recipe.recipe_type instanceof Array) recipe.recipe_type = recipe.recipe_type.join(",")
            await ApiRequest(ApiCall(Api.UpdatePlanRecipe, recipe, id))
            return await commit('savePlanRecipe', [recipe, id])
        },
        async deletePlanRecipe({commit}, id) {
            await ApiRequest(ApiCall(Api.DeletePlanRecipe, id))
            return await commit('deletePlanRecipe', id);
        },
        async addPlanRecipeImage({commit}, [file, id]) {
            const res = await ApiRequest(ApiCall(Api.AddPlanRecipeImage, {file}, id))
            return await commit('addPlanRecipeImage', [res.location, id])
        },
        async deletePlanRecipeImage({commit}, id) {
            await ApiRequest(ApiCall(Api.DeletePlanRecipeImage, id))
            return await commit('deletePlanRecipeImage', [id])
        },
    },
    mutations : {
        setPlanRecipes: (state, planRecipes) => state.planRecipes = planRecipes,
        setRecipes: (state, recipes) => state.recipes = recipes,
        saveRecipe: (state, [recipe, id]) => {
            if (id) {
                state.recipes = state.recipes.map(it => it.id === recipe.id ? recipe : it)
            }
            else {
                state.recipes = [...state.recipes, recipe];
            }
            return state
        },
        createPlanRecipe(state, recipe) {
            state.planRecipes.push(recipe)
        },
        deleteRecipe(state, id) {
            state.recipes = state.recipes.filter(it => it.id !== id)
        },
        deletePlanRecipe(state, id) {
            state.planRecipes = state.planRecipes.filter(it => it.id !== id)
        },
        savePlanRecipe : (state,[recipe,id]) => {
            if (id) {
                state.planRecipes = state.planRecipes.map(it => it.id === recipe.id ? recipe : it)
            }
            else {
                state.planRecipes = [...state.planRecipes, recipe]
            }
        },
        addPlanRecipeImage : (state, [location, id]) => {
            if (id) {
                state.planRecipes = state.planRecipes.map(it => it.id === id ? {
                    ...it,
                    images : [...it.images, {id, image: location}]
                } : it)
            }
        },
        deletePlanRecipeImage(state, [id]) {
            state.planRecipes = state.planRecipes.map(it => ({
                ...it,
                images: it.images.filter(img => {
                    return img.id*1 !== id*1
                })
            }))
        }
    }
}
