import {getToken} from './Storage';

export default {
    // login
    CheckLogin : { path : "/user/check-login", method: "get", token: true},
    Login : { path : '/user/login', method: "post", body: true },
    Logout : { path: '/user/logout', method: "get", token: true },

    // admin

        // users
        ListUsers : { path: '/user/list', method: "get", token: true },
        CreateUser : { path: '/user/add', method: "post", body: true, token: true },
        UpdateUser : { path: '/user/edit', method: "post", body: true, param: true, token: true },
        DeleteUser : { path: '/user/delete', method: "get", param: true, token: true },

        // roles
        ListUserPermissions : { path: '/user-roles/permissions', method: "get", token: true },
        ListUserRoles : { path: '/user-roles/list', method: "get", token: true },
        CreateUserRole : { path: '/user-roles/add', method: "post", body: true, token: true },
        UpdateUserRole : { path: '/user-roles/edit', method: "post", body: true, param: true, token: true },
        DeleteUserRole : { path: '/user-roles/delete', method: "get", param: true, token: true },

    // editor

        // Set Plan
        SetCurrentPlan : { path: '/user/set-plan', method: "get", param: true, token: true },

        // locales
        ListLocales : { path: '/foods/locales', method: "get", token: true },

        // recipes
        ListRecipes : { path: '/foods/recipes', method: "get", token: true },
        ListPlanRecipes : { path: '/recipes/list', method: "get", token: true },
        GetPlanRecipeLocales : { path: '/recipes/locales', method: "get", param: true, token: true },
        CreatePlanRecipe : { path: '/recipes/add', method: "post", body: true, token: true },
        UpdatePlanRecipe : { path: '/recipes/edit', method: "post", body: true, param: true, token: true },
        DeletePlanRecipe : { path: '/recipes/delete', method: "get", param: true, token: true },
        CreatePlanRecipeLocale : { path: '/recipes/add', method: "post", body: true, param: true, token: true },
        UpdatePlanRecipeLocale : { path: '/recipes/edit', method: "post", body: true, param: true, token: true },
        AddPlanRecipeImage : { path: '/recipes/add-image', method: "post", body: true, param: true, token: true, upload: 'file' },
        DeletePlanRecipeImage : { path: '/recipes/delete-image', method: "get", param: true, token: true },

        // categories
        ListCategories : { path: '/categories/list', method: "get", token: true },
        CreateCategory : { path: '/categories/add', method: "post", body: true, token: true },
        UpdateCategory : { path: '/categories/edit', method: "post", body: true, param: true, token: true },
        DeleteCategory : { path: '/categories/delete', method: "get", param: true, token: true },

        // Menus Plans
        ListMenusPlans : { path : '/menus-plans/list', method: "get", token: true },
        GetMenusPlan : { path : '/menus-plans/get/:id', method: "get", token: true },
        CreateMenusPlan : { path : '/menus-plans/add', method: "post", body: true, token: true },
        UpdateMenusPlan : { path : '/menus-plans/edit', method: "post", param: true, body: true, token: true },
        DeleteMenusPlan : { path : '/menus-plans/delete', method: "get", param: true, token: true },


        // tags
        ListTags : { path: '/tags/list', method: "get", token: true },
        CreateTag : { path: '/tags/add', method: "post", body: true, token: true },
        UpdateTag : { path: '/tags/edit', method: "post", body: true, param: true, token: true },
        DeleteTag : { path: '/tags/delete', method: "get", param: true, token: true },

        // plans
        ListPlans : { path: '/plan/list', method: "get", token: true },
        GetPlanLocales : { path: '/plan/locales', method: "get", param: true, token: true },
        CreatePlanLocale : { path: '/plan/add', method: "post", body: true, param: true, token: true },
        UpdatePlanLocale : { path: '/plan/edit', method: "post", body: true, param: true, token: true },

        CreatePlan : { path: '/plan/add', method: "post", body: true, token: true },
        UpdatePlan : { path: '/plan/edit', method: "post", body: true, param: true, token: true },
        DeletePlan : { path: '/plan/delete', method: "get", param: true, token: true },

        SetPlanSettings : { path: '/plan', method: "post", body: true, token: true},

    // consumer

    // my plan
    GetMyPlan : { path: '/plan', method: "get", token: true},
    GetAvailableSnapshots : { path: '/plan/snapshots', method: "get", token: true },


    // groups
    ListPrefGroups : { path: '/pref-groups/list', method: "get", token: true },
    CreatePrefGroup : { path: '/pref-groups/add', method: "post", body: true, token: true },
    UpdatePrefGroup : { path: '/pref-groups/edit', method: "post", body: true, param: true, token: true },
    DeletePrefGroup : { path: '/pref-groups/delete', method: "get", param: true, token: true },
    GetPrefGroupIngredients : {path: '/pref-groups/ingredients', method: "get", param: true, token: true },

    ListRepGroups : { path: '/rep-groups/list', method: "get", token: true },
    CreateRepGroup : { path: '/rep-groups/add', method: "post", body: true, token: true },
    UpdateRepGroup : { path: '/rep-groups/edit', method: "post", body: true, param: true, token: true },
    DeleteRepGroup : { path: '/rep-groups/delete', method: "get", param: true, token: true },
    GetRepGroupIngredients : {path: '/rep-groups/ingredients', method: "get", param: true, token: true },


    ListServingSizes : { path: '/foods/serving-sizes', method: "get", token: true },
    ListServingSizesLocale : { path: '/foods/serving-sizes', method: "get", /*param: true,*/ token: true },
    ListIngredients : {path: '/foods/ingredients', method: "get", token: true },
    GetIngredientLocales : {path: '/ingredients/locales', method: "get", param: true, token: true },
};

export function ApiCall(endpoint, body = null, query = null, param = null) {

    // replace body for query and then to params to allow using 1st param for queries or params
    if (!endpoint.body && (endpoint.query || endpoint.param) && !query) { query = body; body = null; }
    if (!endpoint.query && endpoint.param && !param) { param = query; }

    let req = {
        body : {},
        method: endpoint.method,
        param: endpoint.param,
        url : '',
    };

    if (endpoint.body) {
        req.body = body;
    }

    let url = new URL(process.env.VUE_APP_API_URL + endpoint.path);
    if (endpoint.param) url += (url[url.length-1]==='/'?'':'/') + param;

    if (endpoint.query) {
        for (let i of endpoint.query) if (typeof query[i] !== "undefined") url.searchParams.append(i, query[i]);
    }

    if (endpoint.upload) {
        req.upload = endpoint.upload;
        if (!req.headers) req.headers = {}
        req.headers['Content-Type'] = "multipart/form-data";
    }

    if (endpoint.token) {
        const token = getToken();
        if (token) {
            if (!req.headers) req.headers = {};
            req.headers.token = token;
        }
    }

    req.url = url.toString();

    return req;
}

export const doRequest = async (uri, method = "GET", body = {}, additionalHeaders = {}, upload = '') => {

    let headers = new Headers();

    const params = {
        method, headers,
        credentials: 'include'
    };

    if (method.toLowerCase() === "post" || method.toLowerCase() === "put") {
        if (!additionalHeaders['Content-Type']) {
            headers.append('Content-Type','application/json');
        }
        params.body = JSON.stringify(body);
    }

    if (additionalHeaders) {
        for (let h in additionalHeaders)
            headers.append(h, additionalHeaders[h])
    }

    if (upload) {
        const data = new FormData();
        data.append(upload, body[upload])
        headers.delete('Content-Type')
        params.body = data;
    }

    try {
        const response = await fetch(uri, params);
        if (response.status === 404 || response.status === 400) {
            const resJson = await response.json();
            throw({fetchError : resJson});
        }
        else if (response.status === 200) {
            return await response.json();
        }
    } catch (fetchError) {
        throw({fetchError});
    }
};

export async function ApiRequest(call) {
    return await doRequest(call.url, call.method, call.body, call.headers, call.upload);
}
