<template>
  <div class="splash-container">
    <div :class="'splash'+(isLoggedIn?'':' disabled')">
      <div class="splash-inner">
        <h1>Fito.ai</h1>
        <p>Recipes Manager</p>
        <div class="plan" :class="planName?'active':''">{{planName}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Splash",
  computed: {
    ...mapGetters(['isLoggedIn', 'loginError', 'currentPlan','myPlan']),
    planName() {
      if (this.currentPlan && this.myPlan) {
        if (this.myPlan) return this.myPlan.editor_title ? this.myPlan.editor_title : this.myPlan.name;
      }
      return null;
    }
  },
  methods : {
    ...mapActions(['logout', 'getMyPlan', 'setCurrentPlan']),
  },
  async mounted() {
    if (!this.myPlan) await this.getMyPlan()
  }
}
</script>
