<template>
  <div>
    <div class="flexy">
      <div class="diagonal-wrap main">
        <input type="text" v-model="newName" placeholder="Plan Recipe Name">
      </div>
    </div>
    <div class="flexy">
      <div class="flexy end padded">
        <div :class="'diagonal-wrap'+(newName?'':' disabled')">
          <button :disabled="!newName" @click="onSubmit">Add</button>
        </div>
        <button @click="cancel" class="unwrap">Cancel</button>
      </div>
    </div>
    <NutritionCounter label="Nutrients for 100g" :ingredients="data.ingredients" :compact="true" />
  </div>
</template>

<script>
import NutritionCounter from "@/components/ui/NutritionCounter";
export default {
  name: "AddPlanIngredientForm",
  components: {NutritionCounter},
  props: ['submit','cancel','data'],
  data() {
    return {
      newName: ''
    }
  },
  computed : {
    addIngredients() { return [{ ingredient: this.data, amount_g: 100, serving_amount: 1}] }
  },
  mounted() {
    this.newName = this.data.name;
  },

  methods : {
    onSubmit() {
      this.submit(this.data.id, this.newName);
    }
  }
}
</script>

<style scoped>

</style>
