import Api, {ApiCall, ApiRequest} from "@/lib/Api";

export default {
    state : {
        tags : [],
        savedTag: null
    },
    getters : {
        allTags: state => state.tags,
        savedTag: state => state.savedTag,
    },
    actions : {
        async deleteTag({commit}, id) {
            await ApiRequest(ApiCall(Api.DeleteTag, id))
            return await commit('deleteTag', id)
        },
        async getTags({commit}) {
            const tags = await ApiRequest(ApiCall(Api.ListTags));
            return await commit('setTags', tags);
        },
        async updateTag({commit, dispatch}, [tag, id]) {
            const res = await ApiRequest(ApiCall(Api.UpdateTag, tag, id))
            if (res.error) {
                await dispatch('setError', res.error)
            }
            else {
                tag.id = res.id
                await dispatch('setSuccess', 'Tag Updated')
                return await commit('saveTag', [tag, id])
            }
        },
        async createTag({commit, dispatch}, tag) {
            const res = await ApiRequest(ApiCall(Api.CreateTag, tag))
            if (res.error) {
                await dispatch('setError', res.error)
            }
            else {
                tag.id = res.insertId || res.id
                await dispatch('setSuccess', 'Tag Created')
                return await commit('saveTag', [tag, null])
            }
        },
    },
    mutations : {
        setTags: (state, tags) => state.tags = tags,
        saveTag: (state, [tag, id]) => {
            state.savedTag = tag
            if (id) {
                state.tags = state.tags.map(it => it.id === tag.id ? tag : it)
            }
            else {
                state.tags = [...state.tags, tag];
            }
            return state
        },
        deleteTag(state, id) {
            state.tags = state.tags.filter(it => it.id !== id)
        },
    }
}
