export function delayedIf(condition, callback, timeout = 1000) {
    return new Promise((success) => {
        if (condition) {
            setTimeout(() => {
                callback()
                success()
            }, timeout)
        }
        else {
            callback()
            success()
        }

    })
}

export function delayedIfLoading(condition, callback, loadingCallback, testRetry = null, retryPenalty = null, timeout = 1000) {
    loadingCallback(true)
    const cb = async () => {
        if (testRetry && retryPenalty) {
            if (!testRetry()) return retryPenalty()
        }
        await (callback instanceof Promise?callback:callback())
        await loadingCallback(false)
    }
    return delayedIf(condition, cb, timeout)
}

export function actionIf(conditionAndPromisePairs) {
    const actions = []
    if (!(conditionAndPromisePairs[0] instanceof Array)) conditionAndPromisePairs = [conditionAndPromisePairs]
    for (let p of conditionAndPromisePairs) {
        if (p[0] && p[1]) {
            if (!(p[1] instanceof Promise)) p[1] = p[1]()
            actions.push(p[1])
        }
    }
    return Promise.all(actions)
}

export function doAll(actions) {
    return Promise.all(actions)
}
