import Api, {ApiCall, ApiRequest} from "@/lib/Api";

export default {
    state : {
        menusPlans : [],
        savedMenusPlan: null
    },
    getters : {
        allMenusPlans: state => state.menusPlans,
        savedMenusPlan: state => state.savedMenusPlan,
    },
    actions : {
        async deleteMenusPlan({commit}, id) {
            await ApiRequest(ApiCall(Api.DeleteMenusPlan, id))
            return await commit('deleteMenusPlan', id)
        },
        async getMenusPlans({commit}) {
            const menusPlans = await ApiRequest(ApiCall(Api.ListMenusPlans));
            return await commit('setMenusPlans', menusPlans);
        },
        async updateMenusPlan({commit, dispatch}, [menusPlan, id]) {
            const res = await ApiRequest(ApiCall(Api.UpdateMenusPlan, menusPlan, id))
            if (res.error) {
                await dispatch('setError', res.error)
            }
            else {
                menusPlan.id = res.id
                await dispatch('setSuccess', 'Menus Plan Updated')
                return await commit('saveMenusPlan', [menusPlan, id])
            }
        },
        async createMenusPlan({commit, dispatch}, menusPlan) {
            const res = await ApiRequest(ApiCall(Api.CreateMenusPlan, menusPlan))
            if (res.error) {
                await dispatch('setError', res.error)
            }
            else {
                menusPlan.id = res.insertId || res.id
                await dispatch('setSuccess', 'Menus Plan Created')
                return await commit('saveMenusPlan', [menusPlan, null])
            }
        },
    },
    mutations : {
        setMenusPlans: (state, menusPlans) => state.menusPlans = menusPlans,
        saveMenusPlan: (state, [menusPlan, id]) => {
            state.savedMenusPlan = menusPlan
            if (id) {
                state.menusPlans = state.menusPlans.map(it => it.id === menusPlan.id ? menusPlan : it)
            }
            else {
                state.menusPlans = [...state.menusPlans, menusPlan];
            }
            return state
        },
        deleteMenusPlan(state, id) {
            state.menusPlans = state.menusPlans.filter(it => it.id !== id)
        },
    }
}
