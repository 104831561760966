<template>
  <div v-if="planIcon" class="panel inline">
    <div class="panel-header">
      <p>Ingredient Icon: <b>{{planIcon.id}}</b></p>
    </div>
    <div class="panel-body">
      <div class="sides">
        <div class="main">
          <div class="full-scroller in-panel">
            <IngredientIconForm :icon="planIcon" @update="updateIconProp" :existing="allPlanIcons" />
          </div>
        </div>
        <div class="side">
          <div class="full-scroller in-panel">
            <div class="box">
              <div class="diagonal-wrap">
                <button class="large-button" @click="onSubmit">{{ planIcon.id?'Update Icon':'Create Icon' }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import IngredientIconForm from "@/components/forms/IngredientIconForm";
import Api, {ApiCall, ApiRequest} from "@/lib/Api";
import {delayedIfLoading} from "@/lib/Async";

export default {

  name: "PlanIcon",
  components: {IngredientIconForm, },

  data() {
    return {
      planIcon : null,
      ingredients: [],
    }
  },
  async mounted() {
    await delayedIfLoading(!this.isLoggedIn, this.initData, this.setLoading, () => this.isLoggedIn, () => this.$router.push('/'))
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allPlanIcons']),
  },

  methods: {
    ...mapActions(['getPlanIcons', 'setLoading', 'setSuccess', 'updatePlanIcon', 'createPlanIcon']),

    async onSubmit() {
      let isNew = false;
      await this.setLoading(true);

      if (this.planIcon.id) { //update
        await this.updatePlanIcon([this.planIcon, this.planIcon.id])
      } else { // create new
        isNew = true;
        await this.createPlanIcon(this.planIcon);
      }
      if (this.planIcon.file) {
        await ApiRequest(ApiCall(Api.UploadPlanIcon, {file: this.planIcon.file}, this.planIcon.id))
      }

      if (isNew && this.planIcon.id) {
        await this.$router.push('/plan-ingredient-icon/' + this.planIcon.id)
        await this.initData()
        this.planIcon = {...this.planIcon}
      }

      await this.setLoading(false);
      await this.setSuccess("Icon saved");
    },

    updateIconProp([prop, value]) {
      this.planIcon[prop] = value;
    },

    onUpdate(values) {
      this.planIcon = {...this.planIcon, ...values};
    },

    async initData() {
      if (this.allPlanIcons.length <= 0) await this.getPlanIcons();
      this.planIcon = !isNaN(this.$route.params.id * 1) ? this.allPlanIcons.find(it => it.id === this.$route.params.id * 1) : {color:"#000000"}
    },
  },



}
</script>
