<template>
  <div class="tag-form">
    <h3>Add</h3>
    <div class="form-input flexy">
      <div>
        <div class="diagonal-wrap main">
          <input v-model="newTitle" placeholder="Title" />
        </div>
        <textarea v-model="newContent" placeholder="Description"></textarea>
      </div>
      <div :class="'diagonal-wrap square-wrap' + (newContent ? '':' disabled')">
        <button class="square" @click="addInstruction"><span class="icon-text">+</span></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InstructionsForm",
  props: ["onAdd"],
  data() {
    return {
      newTitle: "",
      newContent: "",
    }
  },
  methods: {
    addInstruction() {
      if (this.newContent) {
        this.onAdd(this.newTitle, this.newContent);
        this.newTitle = this.newContent = '';
      }
    }
  }
}
</script>
