<template>
    <div class="panel inline">
      <ConfirmModal v-if="menusPlanForDeletion" item-type="Menus Plan" :item-name="menusPlanForDeletion.name" :confirm="confirmDeleteMenusPlan" :cancel="cancelDeleteMenusPlan" />
      <ListPanel title="Menus Plans" :fields="fields" :items="allMenusPlans" :on-add="addNewMenusPlan" :on-delete="askToDeleteMenusPlan" :on-row="goto" />
    </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ConfirmModal from "@/components/ui/ConfirmModal";
import ListPanel from "@/components/ui/ListPanel";
import {actionIf, delayedIfLoading} from "@/lib/Async";

export default {
  name: "MenusPlans",
  components: {ListPanel, ConfirmModal },

  data() {
    return {
      menusPlanForDeletion: null,
      search : "",
      fields : [
        {label: "Name", prop: "name", primary: true, sortable: true},
        {label: "Type", prop: "type", primary: true, sortable: true},
        {label: "Actions", prop: "", width: "10%", actions: [
            {label: "x", type: "danger-btn", action: "delete", tooltip: "Delete"}
          ]}
      ]
    }
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allMenusPlans', 'allLocales', 'allPlans']),
  },

  async mounted() {
    await delayedIfLoading(!this.isLoggedIn, actionIf([
        [!this.allMenusPlans.length, this.getMenusPlans],
        ]), this.setLoading,
        () => this.isLoggedIn, () => this.$router.push('/'))
  },

  methods: {
    ...mapActions(['getMenusPlans','setLoading','deleteMenusPlan', 'getLocales', 'getPlans']),
    goto(menusPlan) {
      this.$router.push('/plan-menus-plans/'+menusPlan.id)
    },
    addNewMenusPlan() {
      this.$router.push('/plan-menus-plans/new')
    },
    askToDeleteMenusPlan(menusPlan) {
      this.menusPlanForDeletion = menusPlan;
    },
    cancelDeleteMenusPlan() { this.menusPlanForDeletion = null },
    confirmDeleteMenusPlan() {
      this.deleteMenusPlan(this.menusPlanForDeletion.id)
      this.menusPlanForDeletion = null
    }
  },
}
</script>
