<template>
  <div v-if="tag" class="panel inline">
    <div class="panel-header">
      <p>Tag: <b>{{tag.name}}</b></p>
    </div>
    <div class="panel-body">
      <div class="sides">
        <div class="main">
          <div class="full-scroller in-panel">
            <app-form :fields="fields" :data="tag" :meta="{locales: allLocales, plans: allPlans}" @update="onUpdate" />
          </div>
        </div>
        <div class="side">
          <div class="full-scroller in-panel">
            <div class="box">
              <div class="diagonal-wrap">
                <button class="large-button" @click="onSubmit">{{ tag.id?'Update Tag':'Create Tag' }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import tagFields from "@/lib/form-fields/tag";
import AppForm from "@/components/ui/AppForm";
import {actionIf, delayedIfLoading} from "@/lib/Async";

export default {

  components: { AppForm },

  data() {
    return {
      fields : tagFields,
      tag : null,
      locales : null,
      settingLocale : null
    }
  },

  name: "Tag",
  computed: {
    ...mapGetters(['isLoggedIn', 'allTags', 'allLocales','allPlans','savedTag']),

  },

  async mounted() {
    console.log("huuu")
    await delayedIfLoading(!this.isLoggedIn, this.initData, this.setLoading, () => this.isLoggedIn, () => this.$router.push('/'))
  },

  methods: {
    ...mapActions(['getTags', 'getLocales','getPlans', 'setLoading','setSuccess','setError','updateTag','createTag']),

    async onSubmit() {
      await this.setLoading(true);
      if (this.tag.id) { //update
        await this.updateTag([this.tag, this.tag.id])
      }
      else { // create new
        await this.createTag(this.tag);
        if (this.savedTag.id) {
          await this.$router.push('/plan-tag/'+this.savedTag.id)
          await this.initData()
          this.tag = {...this.savedTag }
        }
      }
      await this.setLoading(false);
    },

    onUpdate(values) {
      this.tag = {...this.tag, ...values};
    },

    async initData() {
      await actionIf([
        [!this.allTags.length, this.getTags],
      ])

      this.tag = !isNaN(this.$route.params.id*1) ? this.allTags.find(it => it.id === this.$route.params.id*1) :
          { name: "" }
    },
  },


}
</script>
