let ingredientsDb;

function setIngredientsDb(db, fullDb = null) {
    ingredientsDb = db;
    if (fullDb) {
        let ingredient
        ingredientsDb = ingredientsDb.map(ing => {
            ingredient = fullDb.find(it => it.id === ing.ingredient_id)
            if (ingredient) ing.ingredient = ingredient;
            return ing;
        })
    }
}

function ingredientFromDb(val, param = 'ingredient_id') {
    return ingredientsDb.find(i => i[param] === val);
}

function getAvailableMeals(buckets) {
    return buckets;
}

function mealNutrition(meal, raw = false, ingDb, fullDb = false, maxRange = false) {
    const meta = ['name','alternative_group','created_at','id','updated_at','tags','icon', 'instructions','serving_sizes'];
    let ingredients = [];

    if (meal.ingredients && meal.ingredients instanceof Array) ingredients = meal.ingredients;
    else if (meal instanceof Array) ingredients = [...meal]

    if (meal.recipes && meal.recipes.length > 0) {
        for (let r of meal.recipes) {
            if (r && r.ingredients) {
                ingredients = [...ingredients, ...r.ingredients]
            }
        }
    }
    const nutrition = {};
    let theIng, amount;
    if(!(ingredients instanceof Array)) console.log(ingredients)
    for (let ing of ingredients) {
        theIng = null;
        if (!ing || ing.inactive) continue;
        if (ingDb) {
            if (fullDb) {
                theIng = ingDb.find(it => it.id === ing.ingredient_id || (ing.ingredient && it.id === ing.ingredient.id));
            }
            else {
                theIng = ingDb.find(it => it.ingredient_id === ing.ingredient_id || (ing.ingredient && (ing.ingredient.ingredient_id && it.ingredient_id === ing.ingredient.ingredient_id || it.ingredient_id === ing.ingredient.id)));
            }
        } else {
            if (ing.ingredient) theIng = ing.ingredient;
        }
        if (!theIng) continue;
        for (let nut in theIng.nutrition) {
            if (meta.indexOf(nut) > -1) continue;
            if (!nutrition[nut]) nutrition[nut] = 0;
            if (maxRange) {
                let max = ing.max ? (ing.serving_size_id ? ing.amount_g * ing.max : ing.max) : (ing.serving_size_id ? ing.amount_g * ing.serving_amount : ing.serving_amount)
                nutrition[nut] += theIng.nutrition[nut] / 100 * max
            }
            else {
                amount = ing.max ? Math.min(ing.amount_g * (ing.serving_size_id ? ing.serving_amount : 1), ing.serving_size_id ? ing.max * ing.amount_g : ing.max) : ing.amount_g * (ing.serving_size_id ? ing.serving_amount : 1)
                nutrition[nut] += theIng.nutrition[nut] * amount / 100;
            }
        }
    }
    if (!raw) for (let i in nutrition) nutrition[i] = parseFloat(nutrition[i]).toFixed(2)*1;
    return nutrition;
}

function mealsNutrition(meals) {
    let r, res = {};
    if (meals.meals) meals = meals.meals;
    for (let meal of meals) {
        r = (meal.nutrition) ? meal.nutrition : mealNutrition(meal);
        for (let p in r) {
            if (isNaN(r[p]*1)) continue;
            if (!res[p]) res[p] = 0;
            res[p] += r[p]
        }
    }
    return res;
}

function NutritionSorter(res, data, params) {

    let {calories, protein, fat, powerCal, powerProtein, powerFat} = params;

    return res.sort((a, b) => {
        a = data['nutrition'][a];
        b = data['nutrition'][b];

        let ac = Math.abs(calories - a.calories), bc = Math.abs(calories - b.calories);
        let ap = Math.abs(protein - a.protein_g * 4), bp = Math.abs(protein - b.protein_g * 4);
        let af = Math.abs(fat - a.fat_g * 9), bf = Math.abs(fat - b.fat_g * 9);

        let aa = 0, bb = 0;
        aa += ac * powerCal;
        aa += ap * powerProtein;
        aa += af * powerFat;

        bb += bc * powerCal;
        bb += bp * powerProtein;
        bb += bf * powerFat;

        return aa < bb ? -1 : 1;
    })
}

function getDailyNutrition(plan, keySet) {
    if (plan.meals && plan.meals instanceof Array) plan = plan.meals;
    let nutrition = {}, m, meal, kkey;
    for (let i = 0; i < 5; i++) {
        kkey = parseInt(keySet[i], 36);
        if (plan[i] && keySet && plan[i][kkey]) {
            meal = plan[i][kkey];
            m = mealNutrition(meal);
            for (let n in m) {
                if (!nutrition[n]) nutrition[n] = 0;
                nutrition[n] += m[n]*1;
            }
        }
    }
    for (let i in nutrition) nutrition[i] = parseFloat(nutrition[i]).toFixed(2);
    nutrition['protein_cal'] = nutrition['protein_g'] * 4;
    nutrition['fat_cal'] = nutrition['fat_g'] * 9;

    return nutrition;
}

function replaceMealIngredients(ingredients, preferences, groups) {
    if (ingredients.ingredients && ingredients.ingredients instanceof Array) ingredients = ingredients.ingredients;
    let ingredient, preference, pkey = 0;

    for (let i = 0; i < ingredients.length; i++) {
        if (typeof ingredients[i] === "undefined") {
            continue
        }
        ingredient = ingredientFromDb(
            (ingredients[i].ingredient?
                    (ingredients[i].ingredient.ingredient_id?ingredients[i].ingredient.ingredient_id:ingredients[i].ingredient.id):
                    (ingredients[i].ingredient_id? ingredients[i].ingredient_id:ingredients[i].id)
            )); // load tags, alt groups etc.
        if (!ingredient) {
                ingredient = ingredients[i].ingredient
                continue;
        }

        const group = groups.find(it => it.id === ingredient.pref_group_id)

        if (group && preferences && preferences[group.slug]) {
            if (preferences[group.slug] === "none") {
                ingredients[i].inactive = true;
                continue;
            }

            ingredients[i].inactive = false;
            preference = groupPreferences(group, preferences, ingredient.id);
            pkey = 0;
            if (preference.length > 0) {
                console.log(preference)
                const r = ingredients.find(it => it.ingredient_id === preference[pkey].ingredient_id)
                if (r) {
                    if (pkey < preference.length-1) {
                        pkey++
                        console.log(pkey);
                    }
                    // else continue;
                }
                ingredients[i] = replaceIngredient(ingredients[i], preference[pkey].ingredient? preference[pkey].ingredient: preference[pkey]);
            }
            else {
                // ...
            }
        }
    }
    return ingredients;
}

function groupPreferences(group, preferences, ) {
    const res = []
    for (let ing of ingredientsDb) {
        if (ing.pref_group_id === group.id && preferences[group.slug].indexOf(ing.ingredient.slug) > -1) res.push(ing)
    }
    return res;
}

function replaceIngredient(ingredient, target) {
    if (ingredient.ingredient_id === target.ingredient_id) return;
    const fromDb = ingredient.ingredient ? ingredient.ingredient : ingredientFromDb(ingredient.ingredient_id)
    const origNutrition = fromDb.nutrition ? {...fromDb} : ingredientFromDb(ingredient.ingredient_id ? ingredient.ingredient_id: ingredient.id);
    const tData = target.serving_sizes ? target : ingredientFromDb(target.ingredient_id)

    let hasAmount = false, targetHasAmount = false;
    if (fromDb.serving_sizes) for (let size of fromDb.serving_sizes) { if (size.id && ingredient.serving_size_id) { hasAmount = true; break; } }
    if (tData.serving_sizes) for (let size of tData.serving_sizes) { if (size.id) { targetHasAmount = true; break; } }

    ingredient.ingredient = target;
    ingredient.ingredient_id = target.id;
    // change serving size etc.
    const serving = (ingredient.serving_size_id ? ingredient.serving_size_id : '');
    if (hasAmount && targetHasAmount) {
        ingredient = optimizeServing(ingredient, serving, origNutrition, target);
    }
    else {
        if (targetHasAmount) {
            if (!ingredient.needed_amount) ingredient.needed_amount = ingredient.amount_g * origNutrition.calories;
            ingredient = optimizeServing(ingredient, '', origNutrition, target);
        }
        else {
            delete ingredient.serving_name
            delete ingredient.serving_size_id
            ingredient.amount_g = optimizeAmount(ingredient, ingredient.amount_g, origNutrition, target.nutrition);
        }
    }

    return ingredient;
}

function optimizeServing(ingredient, serving, origin, target) {
    let cal = ingredient.needed_amount ? ingredient.needed_amount : (origin.nutrition?origin.nutrition.calories:origin.calories) * ingredient.amount_g / 100 * ingredient.serving_amount,
        cal2, closest = null, closestVal = 99999, clos;

    for (let size of target.serving_sizes) {

        clos = Math.abs(cal - (size.amount_g * target.nutrition.calories/100))
            //cal - (size.amount_g * target.nutrition.calories/100) >= 0 ? Math.abs(cal - (size.amount_g * target.nutrition.calories/100)) : 6666;
        if (clos < closestVal) {
            closestVal = clos;
            closest = size;

            cal2 = target.nutrition.calories * size.amount_g / 100;
        }
    }

    if (closest) { // match closest

        while (ingredient.serving_amount > 1 && Math.abs(cal2*(ingredient.serving_amount-1)-cal) < Math.abs(cal2*(ingredient.serving_amount)-cal)) {
            ingredient.serving_amount--;
        }
        while (Math.abs(cal2*(ingredient.serving_amount+1)-cal) < Math.abs(cal2*(ingredient.serving_amount)-cal)) {
            ingredient.serving_amount++;
        }
        // try finding a bigger size that is identical in grams
        if (ingredient.serving_amount > 1) {
            for (let size of target.serving_sizes) {
                if (size.amount_g === closest.amount_g * ingredient.serving_amount) {
                    closest = size;
                    ingredient.serving_amount = 1;
                    break;
                }
            }
        }
        ingredient.amount_g = closest.amount_g;
        ingredient.serving_size_id = closest.serving_size_id;
    }
    if (!ingredient.needed_amount) ingredient.needed_amount = cal;

    return ingredient;
}

function optimizeAmount(ingredient, amount, origin, target) { // by... fat?
    if (ingredient.needed_amount) return Math.round((ingredient.needed_amount) / (target.nutrition?target.nutrition.calories:target.calories) * 100);
    return Math.round(((origin.nutrition?origin.nutrition.calories:origin.calories) /100* amount) / (target.nutrition?target.nutrition.calories:target.calories) * 100);
}

function applyPreferencesToMeal(meal, preferences, groups, recipes) {
    if (!preferences) preferences = {};
    let recipe = meal.recipes && meal.recipes[0] ? meal.recipes[0] : false
    if (recipe && !recipe.ingredients) recipe = recipes.find(it => it.id === recipe.recipe_id)
    meal.ingredients = replaceMealIngredients(meal.ingredients, preferences, groups);
    if (recipe) {
        meal.recipes[0] = {...recipe, ingredients : replaceMealIngredients(recipe.ingredients, preferences, groups) };
    }
    meal.nutrition = mealNutrition(meal);

    return meal;
}

function applyPreferencesToMeals(meals, preferences, groups, recipes) {
    for (let i = 0; i < meals.length; i++) {
        if (meals[i].is_special) continue;
        meals[i] = applyPreferencesToMeal(meals[i], preferences, groups, recipes);
    }
    return meals;
}

function menuMealReplacements(replacements, meals, planMeal, allMealTypes, allRecipes) {

    // limit alternative groups
    const replacementGroups = {}
    for (let r = 0; r < replacements.length; r++) {
        for (let s = 0; s < replacements[r].length; s++) {
            if (replacements[r][s].rep_group) {
                if (!replacementGroups[r]) replacementGroups[r] = {};
                if (!replacementGroups[r][replacements[r][s].rep_group]) replacementGroups[r][replacements[r][s].rep_group] = []
                replacementGroups[r][replacements[r][s].rep_group].push(s)
            }
        }
    }

    let candidates, closest, nut, vars, recipe, varIndex;

    // replacement group filtering
    if (Object.keys(replacementGroups).length > 0) {
        for (let r in replacementGroups) {
            candidates = []
            for (let grp in replacementGroups[r]) {
                if (replacementGroups[r][grp].length <= 1) continue;
                replacementGroups[r][grp].forEach(index => {
                    if (replacements[r][index]) candidates.push(replacements[r][index])
                })
                nut = mealNutrition(meals[r])
                closest = closestMealIndex(candidates, nut.calories, nut.protein_g, nut.fat_g)
                let added = false
                for (let gi = replacementGroups[r][grp].length - 1; gi >= 0; gi--) {
                    if (added) {
                        replacements[r].splice(replacementGroups[r][grp][gi], 1)
                    } else {
                        replacements[r].splice(replacementGroups[r][grp][gi], 1, candidates[closest])
                        added = true
                    }
                }
            }
        }
    }

    const reps = {}
    let mealTypes, typeIndex
    for (let rep of replacements) {
        mealTypes = rep.meal_type || planMeal(rep.meal_id).meal_type
        if (typeof mealTypes === "string") mealTypes = mealTypes.split(/,/g)
        for (let type of mealTypes) {
            typeIndex = allMealTypes.findIndex(it => it.id === type*1)
            if (!reps[typeIndex]) reps[typeIndex] = []
            reps[typeIndex].push(rep)
        }
    }

    // dynamic recipe filtering
    for (let m in reps) {
        for (let rr = 0; rr < reps[m].length; rr++) {
            if (hasVariations(reps[m][rr], allRecipes)) {
                nut = mealNutrition(meals[m])
                recipe = allRecipes.find(it => it.id === reps[m][rr].recipes[0].recipe_id)
                if (recipe.ingredients) vars = recipeVariations(recipe.ingredients)
                varIndex = closestMealIndex(vars, nut.calories, nut.protein_g, nut.fat_g)
                reps[m][rr] = {...reps[m][rr], name: reps[m][rr].name + '['+varIndex+']', recipes: [{ ...recipe, ingredients: vars[varIndex] }] }
            }
        }
    }

    replacements = []
    for (let k in reps) replacements[k*1] = reps[k];
    return replacements
}

function closestMealIndex(meals, calories, protein_g, fat_g) {
    let nut, distance, minDistance = 999999, closest;

    for (let m = 0; m < meals.length; m++) {
        nut = mealNutrition(meals[m])
        distance = Math.abs(nut.calories-calories) + Math.abs(nut.protein_g-protein_g)*4 + Math.abs(nut.fat_g-fat_g)*9
        if (distance < minDistance) {
            minDistance = distance
            closest = m
        }
    }
    return closest
}

function variationMap(ingredients, index, f) {
    return ingredients.map(ing => {
        if (ing.ingredient && !ing.ingredient_id) ing.ingredient_id = ing.ingredient.id;
        if (ing.is_dynamic) {
            if (ing.serving_size_id) return {...ing, serving_amount: index}
            else return {...ing, amount_g: Math.round(ing.amount_g*f)}
        }
        else if (ing.serving_size_id) return {...ing, serving_amount: ing.is_fixed ? ing.serving_amount : Math.round(ing.serving_amount*f)}
        else return {...ing, amount_g: ing.is_fixed ? ing.amount_g : Math.round(ing.amount_g*f)}
    })
}

function recipeVariations(ingredients) {
    let dyn = null, min, max, gap, f = 1;
    for (let ing of ingredients) {
        if (ing.is_dynamic) {
            dyn = ing;
            min = ing.serving_size_id ? ing.serving_amount : ing.amount_g;
            max = ing.max;
            gap = ing.gap;
            break
        }
    }
    if (dyn === null || min === max || !max) return [ingredients]

    const res = [ingredients]
    if (dyn.serving_size_id) {
        for (let s = min*1+1; s <= max; s++) {
            f = s / min
            res.push(variationMap(ingredients, s, f))
        }
    }
    else if (gap) {
        if (gap.indexOf(',') > -1) {
            gap = gap.split(/,/g).map(it => it*1)
            for (let s of gap) {
                f = s / min
                res.push(variationMap(ingredients, s, f))
            }
        }
        else {
            gap = gap.trim()*1; min = min*1;
            for (let s = min+gap; s < max; s+= gap) {
                f = s / min
                res.push(variationMap(ingredients, s, f))
            }
        }
        f = max / min;
        res.push(variationMap(ingredients, max, f))
    }
    return res;
}
//
function hasVariations(ingredients, allRecipes = null) {
    if (ingredients.recipes) {
        for (let rec of ingredients.recipes) {
            if (!rec.ingredients && allRecipes) rec = allRecipes.find(it => it.id === rec.recipe_id)
            for (let ing of rec.ingredients) {
                if (ing.is_dynamic) return true
            }
        }
    }
}


module.exports = {
    setIngredientsDb,
    getAvailableMeals,
    getDailyNutrition,
    recipeVariations,
    mealNutrition,
    NutritionSorter,
    applyPreferencesToMeals,
    menuMealReplacements,
    mealsNutrition,
    closestMealIndex,
}
