<template>
  <div v-if="category" class="panel inline">
    <div class="panel-header">
      <p>Category: <b>{{category.name}}</b></p>
    </div>
    <div class="panel-body">
      <div class="sides">
        <div class="main">
          <div class="full-scroller in-panel">
            <app-form :fields="fields" :data="category" :meta="{locales: allLocales, plans: allPlans}" @update="onUpdate" />
          </div>
        </div>
        <div class="side">
          <div class="full-scroller in-panel">
            <div class="box">
              <div class="diagonal-wrap">
                <button class="large-button" @click="onSubmit">{{ category.id?'Update Category':'Create Category' }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import categoryFields from "@/lib/form-fields/category";
import AppForm from "@/components/ui/AppForm";
import {actionIf, delayedIfLoading} from "@/lib/Async";

export default {

  components: { AppForm },

  data() {
    return {
      fields : categoryFields,
      category : null,
      locales : null,
      settingLocale : null
    }
  },

  name: "Category",
  computed: {
    ...mapGetters(['isLoggedIn', 'allCategories', 'allLocales','allPlans','savedCategory']),

  },

  async mounted() {
    await delayedIfLoading(!this.isLoggedIn, this.initData, this.setLoading, () => this.isLoggedIn, () => this.$router.push('/'))
  },

  methods: {
    ...mapActions(['getCategories', 'getLocales','getPlans', 'setLoading','setSuccess','setError','updateCategory','createCategory']),

    async onSubmit() {
      await this.setLoading(true);
      if (this.category.id) { //update
        await this.updateCategory([this.category, this.category.id])
      }
      else { // create new
        await this.createCategory(this.category);
        if (this.savedCategory.id) {
          await this.$router.push('/plan-category/'+this.savedCategory.id)
          await this.initData()
          this.category = {...this.savedCategory }
        }
      }
      await this.setLoading(false);
    },

    onUpdate(values) {
      this.category = {...this.category, ...values};
    },

    async initData() {
      await actionIf([
        [!this.allCategories.length, this.getCategories],
      ])

      this.category = !isNaN(this.$route.params.id*1) ? this.allCategories.find(it => it.id === this.$route.params.id*1) :
          { name: "" }
    },
  },


}
</script>
