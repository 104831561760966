<template>
  <div v-if="recipe" class="panel inline">
    <div class="panel-header">
      <p>Recipe: <b>{{recipe.name}}</b></p>
    </div>
    <div class="panel-body">
      <div class="sides">
        <div class="main">
          <div class="full-scroller in-panel">
            <AppForm :fields="fields" :data="recipe" @update="onUpdate" />
            <h3>Categories</h3>
            <div class="box recipe-tags">
              <CheckboxesForm :data="recipe.categories" :items="allCategories" label-prop="name" value-prop="id" data-prop="category_id" export-value-prop="category_id" :on-change="setCategories" />
            </div>
            <h3>Tags</h3>
            <div class="box recipe-tags">
              <CheckboxesForm :data="recipe.tags" :items="allTags" label-prop="name" value-prop="id" data-prop="tag_id" export-value-prop="tag_id" :on-change="setTags" />
            </div>
            <div class="box recipe-images" v-if="recipe.id">
              <h3>Images</h3>
              <div v-if="recipe.images" class="flexy">
                <div v-for="(image, i) in recipe.images" :key="i">
                  <div class="preview-box" :style="{backgroundImage: 'url('+image.image+')'}">
                    <div class="danger-btn" @click="removeImage(image.id)">x</div>
                  </div>
                </div>
              </div>
              <h4>Add New Image</h4>
              <div class="form-input flexy">
                <img v-if="newImage" :src="newImage" class="preview-box" />
                <input type="file" @change="setNewImage" placeholder="Upload new Image" />
                <button class="square flex-0" @click="addImage"><span class="icon-text">+</span></button>
              </div>
            </div>
            <RecipeIngredientForm :ingredients="allRecipeIngredients" :passive="true" />

          </div>
        </div>
        <div class="side">
          <div class="full-scroller in-panel">
            <div class="box recipe-instructions" v-if="recipe.id">
              <h3>Instructions</h3>
              <TagList :tags="recipe.instructions" prop="title" secondary="content" tag-key="id" @remove-tag="deleteInstruction" />
              <InstructionsForm :on-add="addInstruction" />
            </div>
            <div class="box" v-if="allRecipeIngredients && allRecipeIngredients.length">
              <NutritionCounter :ingredients="allRecipeIngredients" />
            </div>
            <div class="box">
              <div class="diagonal-wrap">
                <button class="large-button" @click="onSubmit">{{ recipe.id?'Update Recipe':'Create Recipe' }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import recipeFields from "@/lib/form-fields/recipe";
import AppForm from "@/components/ui/AppForm";
import RecipeIngredientForm from "@/components/forms/RecipeIngredientForm";
import NutritionCounter from "@/components/ui/NutritionCounter";
import Api, {ApiCall, ApiRequest} from "@/lib/Api";
import {actionIf, delayedIfLoading} from "@/lib/Async";
import TagList from "@/components/ui/TagList";
import InstructionsForm from "@/components/forms/InstructionsForm";
import CheckboxesForm from "@/components/forms/CheckboxesForm";

const localeFields = [
  {name: "name", label:"Name", type:"text"},
  {name: "description", label:"Description", type:"textarea"},
]

export default {
  name: "Recipe",
  components: {CheckboxesForm, InstructionsForm, TagList, AppForm, RecipeIngredientForm, NutritionCounter },
  data() {
    return {
      fields : recipeFields,
      newInstruction : "",
      newImage : null,
      newImageFile : null,
      localeFields,
      locales : null,
      settingLocale : null
    }
  },

  async mounted() {
    await delayedIfLoading(!this.isLoggedIn, this.initData, this.setLoading, () => this.isLoggedIn, () => this.$router.push('/'))
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allPlanRecipes', 'allLocales','allRecipes','allCategories','allTags']),
    allRecipeIngredients() {
      const recipe = this.allRecipes.find(it => it.id === this.recipe.recipe_id)
      if (recipe && recipe.ingredients) {
        return recipe.ingredients;
      }
      return []
    },
    recipe() {
      if (!this.allPlanRecipes) return {}
      const r = this.allPlanRecipes.find(it => it.id*1 === this.$route.params.id*1)
      return r ? r : {}
    }
  },

  methods: {
    ...mapActions(['getRecipes','getPlanRecipes','setLoading','setSuccess','getLocales','updatePlanRecipe','addPlanRecipeImage','deletePlanRecipeImage','getCategories','getTags']),

    async onSubmit() {

      await this.setLoading(true);
      console.log(this.recipe)
      if (this.recipe.id) { //update
        await this.updatePlanRecipe([this.recipe, this.recipe.id])
      }
      else { // create new
        await this.createPlanRecipe(this.recipe);
        if (this.recipe.id) {
          await this.$router.push('/plan-recipe/'+this.recipe.id)
          await this.initData()
        }
      }
      await this.setLoading(false);
      await this.setSuccess("Recipe saved");
    },

    setNewImage(e) {
      if (e.target.files) {
        const [file] = e.target.files
        if (file) {
          this.newImage = `${URL.createObjectURL(file)}`
          this.newImageFile = file
        }
      }
    },

    async addImage() {
      await this.addPlanRecipeImage([this.newImageFile, this.recipe.id])
      this.newImage = this.newImageFile = null;
    },

    async removeImage(id) {
      await this.deletePlanRecipeImage(id)
    },

    setCategories(ids) {
      this.recipe.categories = ids;
    },

    setTags(ids) {
      this.recipe.tags = ids;
    },

    onUpdate(data) {
      for (let p in data) this.$set(this.recipe, p, data[p])
    },

    async initData() {
      await actionIf([
        [!this.allCategories.length, this.getCategories],
        [!this.allTags.length, this.getTags],
        [!this.allPlanRecipes.length, this.getPlanRecipes],
        [!this.allRecipes.length, this.getRecipes],
      ])


      if (window.location.hash.indexOf('special') > -1 && isNaN(this.$route.params.id*1)) {
        this.recipe.is_special = true
      }

      if (this.recipe.id) await this.getRecipeLocales()
    },

    async getRecipeLocales() {
      this.locales = this.recipe.locales//await ApiRequest(ApiCall(Api.GetRecipeLocales, this.recipe.id))
    },

    async addInstruction(title, content) {
      this.recipe.instructions.push({title, content})
    },

    deleteInstruction(index) {
      this.recipe.instructions.splice(index, 1)
    },

    async createRecipeLocale(recipe_id, locale_id, name, description) {
      return await ApiRequest(ApiCall(Api.CreatePlanRecipeLocale, { locale_id, name, description },  recipe_id))
    },
    async updateRecipeLocale(recipe_id, locale_id, name, description) {
      return await ApiRequest(ApiCall(Api.UpdatePlanRecipeLocale, {name, description},  recipe_id+'/'+locale_id))
    },

    hasLocale(locale) {
      return this.locales ? !!this.locales.find(it => it.locale_id === locale.id) : false
    },

    getLocale(locale) {
      return this.locales.find(it => it.locale_id === locale.id)
    },

    async setLocale({name, description = '', id = null}, locale_id) {
      if (id) {
        await this.updateRecipeLocale(this.recipe.id, locale_id, name, description)
        this.locales = this.locales.map(it => it.locale_id === locale_id ? {...it, name, description} : it)
      }
      else {
        const res = await this.createRecipeLocale(this.recipe.id, locale_id, name, description)
        this.locales.push({id: res.insertId, locale_id, name, description})
      }
      this.settingLocale = null
    }
  },




}
</script>
