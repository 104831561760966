import Api, {ApiCall, ApiRequest} from "@/lib/Api";

export default {
    state : {
        categories : [],
        savedCategory: null
    },
    getters : {
        allCategories: state => state.categories,
        savedCategory: state => state.savedCategory,
    },
    actions : {
        async deleteCategory({commit}, id) {
            await ApiRequest(ApiCall(Api.DeleteCategory, id))
            return await commit('deleteCategory', id)
        },
        async getCategories({commit}) {
            const categories = await ApiRequest(ApiCall(Api.ListCategories));
            return await commit('setCategories', categories);
        },
        async updateCategory({commit, dispatch}, [category, id]) {
            const res = await ApiRequest(ApiCall(Api.UpdateCategory, category, id))
            if (res.error) {
                await dispatch('setError', res.error)
            }
            else {
                category.id = res.id
                await dispatch('setSuccess', 'Category Updated')
                return await commit('saveCategory', [category, id])
            }
        },
        async createCategory({commit, dispatch}, category) {
            const res = await ApiRequest(ApiCall(Api.CreateCategory, category))
            if (res.error) {
                await dispatch('setError', res.error)
            }
            else {
                category.id = res.insertId || res.id
                await dispatch('setSuccess', 'Category Created')
                return await commit('saveCategory', [category, null])
            }
        },
    },
    mutations : {
        setCategories: (state, categories) => state.categories = categories,
        saveCategory: (state, [category, id]) => {
            state.savedCategory = category
            if (id) {
                state.categories = state.categories.map(it => it.id === category.id ? category : it)
            }
            else {
                state.categories = [...state.categories, category];
            }
            return state
        },
        deleteCategory(state, id) {
            state.categories = state.categories.filter(it => it.id !== id)
        },
    }
}
