<template>
  <div class="navbar">
    <div class="loading-bar" v-if="isLoading"></div>
    <div class="success-bar" v-if="success">{{success}}</div>
    <div class="error-bar flexy" v-if="appError"><b>Error</b><span>{{appError}}</span></div>
    <div class="title">
      <div :class="'theme-switcher' + (lightTheme?' active':'')" @click="toggleTheme"></div>
      <h1><router-link to="/">{{ myPlan && myPlan.editor_title ? myPlan.editor_title : 'Fito Recipes' }}</router-link></h1>
    </div>
    <div class="menu" v-if="!currentPlan && permissionsContain(currentUser, 'EDITOR')">
      <router-link v-if="isLoggedIn && hasPermission(currentUser, 'ADMIN_USERS')" to="/users"><span>Users</span></router-link>
      <router-link v-if="isLoggedIn && hasPermission(currentUser, 'ADMIN_ROLES')" to="/roles"><span>Roles</span></router-link>
      <router-link v-if="isLoggedIn && hasPermission(currentUser, 'EDITOR_PLANS')" to="/plans"><span>Plans</span></router-link>
    </div>
    <div class="plan-menu" v-if="currentPlan">
      <router-link v-if="isLoggedIn && hasPermission(currentUser, 'PLAN_MEALS')" to="/plan-recipes"><span>My Recipes</span><b class="error-badge" v-if="myMealsErrors">{{myMealsErrors}}</b></router-link>
      <router-link v-if="isLoggedIn && hasPermission(currentUser, 'PLAN_MEALS')" to="/plan-menus-plans"><span>Menus Plans</span></router-link>
      <router-link v-if="isLoggedIn && hasPermission(currentUser, 'PLAN_MEALS')" to="/plan-categories"><span>Categories</span></router-link>
      <router-link v-if="isLoggedIn && hasPermission(currentUser, 'PLAN_MEALS')" to="/plan-tags"><span>Tags</span></router-link>
      <router-link v-if="isLoggedIn && hasPermission(currentUser, 'PLAN_SETTINGS')" to="/plan-settings"><span>Plan Settings</span></router-link>
    </div>
    <div class="user" v-if="isLoggedIn">
      <div class="user-info">
        <span>Logged in as</span>
        <b>{{currentUser.username}}</b>
        <a class="button" @click="doLogout" href="javascript:void(0)">Log Out</a>
      </div>
      <div class="plan-selector" v-if="hasPermission(currentUser, 'EDITOR_PLANS')">
        Plan:
        <div class="diagonal-wrap">
          <v-select @input="setPlan" :options="selectPlans" :reduce="it=>it.id" label="name" placeholder="- Select Plan -" :value="plan?plan.id:0" :clearable="false" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {actionIf, delayedIfLoading} from "@/lib/Async";

export default {
  name: "Navbar",

  computed: {
    ...mapGetters(['isLoggedIn', 'isLoading', 'success', 'appError', 'currentUser','currentPlan','myPlan','allPlans','allPlanRecipes']),
    plan() { return this.allPlans.length ? this.allPlans.find(it => it.id === this.currentPlan):{}  },
    selectPlans() { return [{id: 0, name: "- Fito Database -"}, ...this.allPlans]},
    mealErrors() {
      if (!this.allPlanRecipes) return ''
      let c = 0;
      return c > 0 ? c : ''
    },

    myMealsErrors() {
      return this.missingIngredients(true)
    },
    mealReplacementsErrors() {
      return this.missingIngredients(false)
    }
  },

  data() {
    return {
      lightTheme : false,
    }
  },

  watch : {
    async currentPlan() {
      if (this.currentPlan) {
        await actionIf([
        ])
      }
    }
  },

  async mounted() {
    await delayedIfLoading(!this.isLoggedIn, actionIf([
      [this.isLoggedIn && !this.myPlan, await this.getMyPlan],
    ]), ()=> this.isLoggedIn, () => this.$router.push('/'))

    if (this.permissionsContain(this.currentUser,'EDITOR')) {
      await actionIf([
          [!this.allPlans.length, this.getPlans],
      ])
    }

    if (localStorage.getItem('theme') === 'light' && !this.lightTheme) this.toggleTheme()
  },

  methods : {
    ...mapActions(['logout', 'getPlans', 'setCurrentPlan','getMyPlan' ]),

    hasPermission(user, permission) {
      return user && user.permissions.find(it => it === permission)
    },

    missingIngredients() {
      return 0;
    },

    async setPlan(value) {
      await this.setCurrentPlan(value)
      if (this.$router.currentRoute.path !== '/') await this.$router.push('/')
    },

    doLogout() {
      this.logout();
      this.$router.push('/')
    },

    permissionsContain(user, search) {
      return user && user.permissions && user.permissions.find(it => it.toUpperCase().indexOf(search.toUpperCase()) >= 0)
    },

    toggleTheme() {
      if (document.body.classList.contains('light')) {
        localStorage.setItem('theme','dark')
        this.lightTheme = false;
        document.body.classList.remove('light')
      }
      else {
        localStorage.setItem('theme','light')
        this.lightTheme = true;
        document.body.classList.add('light')
      }
    }
  }
}
</script>
