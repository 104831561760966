import Api, {ApiCall, ApiRequest} from "@/lib/Api";

export default {
    state : {
        ingredients : [],
        planIngredients: [],
        icons: [],
    },
    getters : {
        allIngredients: state => state.ingredients,
        allPlanIngredients: state => state.planIngredients,
        allPlanIcons : state => state.icons,
    },
    actions : {
        async getIngredients({commit}) {
            const {ingredients} = await ApiRequest(ApiCall(Api.ListIngredients));
            return await commit('setIngredients', ingredients);
        },


        async getPlanIngredients({commit}) {
            const ingredients = await ApiRequest(ApiCall(Api.ListPlanIngredients));
            return await commit('setPlanIngredients', ingredients);
        },

    },
    mutations : {
        setIngredients: (state, ingredients) => state.ingredients = ingredients,
        setPlanIngredients: (state, ingredients) => state.planIngredients = ingredients,
        setPlanIngredientIcons: (state, icons) => state.icons = icons,
        saveIngredient: (state, [ingredient, id]) => {
            if (id) {
                state.ingredients = state.ingredients.map(it => it.id === ingredient.id ? ingredient : it)
            }
            else {
                state.ingredients = [...state.ingredients, ingredient];
            }
            return state
        },
        deleteIngredient(state, id) {
            state.ingredients = state.ingredients.filter(it => it.id !== id)
        },

        createPlanIngredient(state, ingredient) {
            state.planIngredients.push(ingredient)
        },
        deletePlanIngredient(state, id) {
            state.planIngredients = state.planIngredients.filter(it => it.id !== id)
        },
        savePlanIngredient : (state,[ingredient,id]) => {
            if (id) {
                state.planIngredients = state.planIngredients.map(it => it.id === ingredient.id ? ingredient : it)
            }
            else {
                state.planIngredients = [...state.planIngredients, ingredient]
            }
        },

        createPlanIngredientIcon(state, icon) {
            if (state.icons) state.icons.push(icon)
        },
        deletePlanIngredientIcon(state, id) {
            state.icons = state.icons.filter(it => it.id !== id)
        },
        savePlanIngredientIcon : (state,[icon,id]) => {
            if (id) {
                state.icons = state.icons.map(it => it.id === icon.id ? icon : it)
            }
            else {
                state.icons = [...state.icons, icon]
            }
        }
    }
}
