import { render, staticRenderFns } from "./InstructionsForm.vue?vue&type=template&id=653248e8&"
import script from "./InstructionsForm.vue?vue&type=script&lang=js&"
export * from "./InstructionsForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports